import React from "react";

const StoreFooter = () => {
  return (
    <div className='container'>
      <footer className='py-3 my-4'>
        <ul className='nav justify-content-center border-bottom pb-3 mb-3'>
          <li className='nav-item'>
            <a
              href='https://piranhaprofits.com/'
              className='nav-link px-2 text-body-secondary'
              target='_blank'
            >
              Official Website
            </a>
          </li>
          <li className='nav-item'>
            <a
              href='https://login.piranhaprofits.com/'
              className='nav-link px-2 text-body-secondary'
              target='_blank'
            >
              Student Login
            </a>
          </li>

          <li className='nav-item'>
            <a
              href='https://malaysia.piranhaprofits.com.my/privacy-policy'
              className='nav-link px-2 text-body-secondary'
              target='_blank'
            >
              Privacy Policy
            </a>
          </li>
          <li className='nav-item'>
            <a
              href='https://malaysia.piranhaprofits.com.my/terms-of-service'
              className='nav-link px-2 text-body-secondary'
              target='_blank'
            >
              Terms of Service
            </a>
          </li>
          <li className='nav-item'>
            <a
              href='https://www.piranhaprofits.com/disclaimer'
              className='nav-link px-2 text-body-secondary'
              target='_blank'
            >
              Disclaimer
            </a>
          </li>
        </ul>
        <p className='text-center text-body-secondary'>
          Piranha Profits® is a registered trademark in Singapore.
          <br />© {new Date().getFullYear()} Piranha Ltd. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default StoreFooter;
